import React, { useState, useMemo } from "react";
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
} from "@tanstack/react-table";
import { Table, Pagination, Form, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { FaSearch } from "react-icons/fa";
import filterIcon from "../images/filter.svg";

const LeadsTable = ({ leads, title }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("Category");
	const [dateFilter, setDateFilter] = useState("All");
	const [sortOrder, setSortOrder] = useState("sortBy");

	const categories = useMemo(
		() => ["Category", ...new Set(leads.map((lead) => lead.category))],
		[leads]
	);

	const getDateFilter = (lead) => {
		const leadDate = new Date(lead.dateCreated);
		const now = new Date();
		let startOfPeriod;

		switch (dateFilter) {
			case "Last 30 days":
				startOfPeriod = new Date();
				startOfPeriod.setDate(now.getDate() - 30);
				return leadDate >= startOfPeriod && leadDate <= now;
			case "Last 60 days":
				startOfPeriod = new Date();
				startOfPeriod.setDate(now.getDate() - 60);
				return leadDate >= startOfPeriod && leadDate <= now;
			case "Last 90 days":
				startOfPeriod = new Date();
				startOfPeriod.setDate(now.getDate() - 90);
				return leadDate >= startOfPeriod && leadDate <= now;
			case "This Year":
				startOfPeriod = new Date(now.getFullYear(), 0, 1);
				return leadDate >= startOfPeriod && leadDate <= now;
			default:
				return true;
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	};

	const filteredAndSortedLeads = useMemo(() => {
		return leads
			.filter((lead) => {
				return (
					(searchTerm === "" ||
						lead.id
							.toString()
							.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						lead.customerName
							.toLowerCase()
							.includes(searchTerm.toLowerCase())) &&
					(categoryFilter === "Category" || lead.category === categoryFilter) &&
					getDateFilter(lead)
				);
			})
			.sort((a, b) => {
				const dateA = new Date(a.dateCreated);
				const dateB = new Date(b.dateCreated);
				if (sortOrder === "sortBy" || sortOrder === "dateDescending") {
					return dateB - dateA;
				} else {
					return dateA - dateB;
				}
			})
			.map((lead) => ({
				...lead,
				dateSubmitted: formatDate(lead.dateCreated),
			}));
	}, [leads, searchTerm, categoryFilter, dateFilter, sortOrder]);

	const columns = useMemo(
		() => [
			{ accessorKey: "id", header: "Lead ID" },
			{ accessorKey: "category", header: "Insurance Category" },
			{
				accessorKey: "customerName",
				header: "Customer Name",
				cell: (info) => <div style={{ width: "180px" }}>{info.getValue()}</div>,
			},
			{
				accessorKey: "phoneNumber",
				header: "Phone No.",
				cell: (info) => <div style={{ width: "120px" }}>{info.getValue()}</div>,
			},
			{ accessorKey: "email", header: "Email" },
			{ accessorKey: "dateSubmitted", header: "Date Submitted" },
			{ accessorKey: "status", header: "Status" },
		],
		[]
	);

	const table = useReactTable({
		data: filteredAndSortedLeads,
		columns,

		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	return (
		<div>
			<h1 className="mb-4">{title}</h1>
			<div
				style={{ borderRadius: "13px", border: "1px solid #D6D6D6" }}
				className="bg-white px-3 mb-4"
			>
				<Row className=" align-items-center justify-content-between">
					<Col md={6}>
						<Row className="align-items-center">
							<Col md={2}>
								<div className="text-center">
									<img
										className=" d-none d-lg-inline-block"
										src={filterIcon}
										alt="Filter"
										style={{
											width: "30px",
										}}
									/>
								</div>
							</Col>

							<Col
								style={{
									borderRight: "1px solid #D6D6D6",
									borderLeft: "1px solid #D6D6D6",
								}}
								className="custom-dropdown py-4"
								md={true}
							>
								<Form.Select
									value={categoryFilter}
									onChange={(e) => setCategoryFilter(e.target.value)}
									className="py-2"
								>
									{categories.map((category, index) => (
										<option key={index} value={category}>
											{category}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col
								style={{ borderRight: "1px solid #D6D6D6" }}
								className="custom-dropdown py-4"
								md={true}
							>
								<Form.Select
									value={dateFilter}
									onChange={(e) => setDateFilter(e.target.value)}
									className="py-2"
								>
									<option value="All">Date</option>
									<option value="Last 30 days">Last 30 days</option>
									<option value="Last 60 days">Last 60 days</option>
									<option value="Last 90 days">Last 90 days</option>
									<option value="This Year">This Year</option>
								</Form.Select>
							</Col>
							<Col
								style={{ borderRight: "1px solid #D6D6D6" }}
								className="custom-dropdown py-4"
								md={true}
							>
								<Form.Select
									value={sortOrder}
									onChange={(e) => setSortOrder(e.target.value)}
									className="py-2"
								>
									<option disabled value="sortBy">
										Sort By
									</option>
									<option className="py-2" value="dateDescending">
										Descending
									</option>
									<option className="py-2" value="dateAscending">
										Ascending
									</option>
								</Form.Select>
							</Col>
						</Row>
					</Col>
					<Col md={5}>
						<Row className="justify-content-end align-items-center">
							<Col md={10}>
								<div
									style={{ overflow: "hidden" }}
									className="d-flex align-items-center rounded-pill bg-med-grey p-1"
								>
									<FaSearch className="ms-3 text-schema-grey" />{" "}
									{/* FontAwesome icon */}
									<Form.Control
										type="text"
										placeholder="Search"
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
										className="flex-grow-1 border-0 bg-med-grey ms-1" // Removed padding and added margin start
										style={{ outline: "none", boxShadow: "none" }} // Remove outline and box shadow on focus
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<div style={{ borderRadius: "13px" }} className="bg-white p-4">
				<div
					className=""
					style={{
						border: "1px solid #D6D6D6",
						borderRadius: "13px",
						overflow: "hidden",
					}}
				>
					<Table className="mb-0" hover borderless size="lg">
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr style={{ backgroundColor: "#F1F4F9" }} key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<th
											className="p-3"
											style={{ backgroundColor: "#F1F4F9" }}
											key={header.id}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row, index) => (
								<tr
									style={{
										borderBottom:
											index !== table.getRowModel().rows.length - 1
												? "1px solid #D6D6D6"
												: "",
									}}
									key={row.id}
								>
									{row.getVisibleCells().map((cell) => (
										<td className="px-3 text-schema-grey" key={cell.id}>
											{cell.column.id === "email" ? (
												<a
													className="w-100 d-block py-4 h-100"
													href={`mailto:${cell.getValue()}`}
													style={{ textDecoration: "none", color: "inherit" }}
												>
													<span className="py-2 d-inline-block">
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</span>
												</a>
											) : (
												<Link
													className="w-100 d-block py-4 h-100"
													to={`/admin/lead-info?leadId=${row.original.airtableId}`}
													style={{ textDecoration: "none", color: "inherit" }}
												>
													{cell.column.id === "status" ? (
														<span
															className="p-2 w-100 Jakarta-Bold "
															style={{
																display: "inline-block",
																color: "#fff",
																textAlign: "center",
																borderRadius: "100px", // Rounded corners
																backgroundColor:
																	cell.getValue() === "Assigned"
																		? "#2DAF49"
																		: "#1470D6",
															}}
														>
															{cell.getValue() || "Not Assigned"}
														</span>
													) : (
														<span className="py-2 d-inline-block">
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext()
															)}
														</span>
													)}
												</Link>
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				{filteredAndSortedLeads.length > 0 && (
					<div className=" mt-3 d-flex justify-content-between align-items-center">
						<span className="text-schema-grey">
							<p>
								Showing{" "}
								{table.getState().pagination.pageIndex *
									table.getState().pagination.pageSize +
									1}
								-
								{Math.min(
									(table.getState().pagination.pageIndex + 1) *
										table.getState().pagination.pageSize,
									filteredAndSortedLeads.length
								)}{" "}
								of {filteredAndSortedLeads.length}
							</p>
						</span>
						<Pagination>
							<Pagination.Prev
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							/>
							<Pagination.Next
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							/>
						</Pagination>
					</div>
				)}
				{filteredAndSortedLeads.length < 1 && (
					<div className=" mt-4 text-center">
						<p className="fs-4 pb-0 mb-0">No Leads Available</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default LeadsTable;
